import React from 'react';
import Layout from '../layout';
import { Link } from 'react-router-dom';


export default function HomePage() {
    return (
        <Layout>
            <div className="">
                <div className="">
                    <div className="row hero-section justify-content-center no-gutters">
                        <div className="col-11 col-md-9 col-lg-8 col-xl-6 text-center">
                            <div
                                className="h-100 d-flex flex-column justify-content-center statement"
                                data-aos="fade-right"
                                data-aos-once="true"
                                data-aos-duration="1000"
                            >
                                <h1 className="mb-4 font-weight-bold">
                                    Streamline your invoicing process for
                                    immediate access to fairly priced capital
                                </h1>
                                <p className="mb-4">
                                    Improving the client vendor relationship
                                    requires an automated invoicing solution,
                                    secured communication channel, low risk on
                                    demand financing, and real-time management
                                    visibility into client/vendor-data - This
                                    is the solution Cowry provides.
                                </p>
                                <div>
                                   
                                       
                                  
                                    <a href="https://app.cowryllc.com/register/business" className="btn btn-secondary mr-3 px-lg-5 rounded">
                                    Get Started
                                    </a>
                                  


                                    <Link to="/contact"   className="btn btn-outline-secondary px-lg-5 px-4 rounded">
                                    Let’s Talk
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 d-none">
                            <div
                                data-aos="fade-left"
                                data-aos-once="true"
                                data-aos-duration="1000"
                            >
                                <img
                                    alt=""
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/html/app/dist/images/9.svg'
                                    }
                                    className="w-100 d-none d-md-none d-lg-block"
                                    width=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <h3 className="text-center web-title mb-5 font-weight-bold">
                        Early pay vendors at a better price with simple invoicing
                        </h3>
                    </div>
                </div>

                <div className="box-section summary-services">
                    <div className="container">

                        <div className="row justify-content-center">
                            <div className="col-12 col-md-8">
                                <Link to="/services" className="">
                                <img
                                        alt=""
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/html/app/dist/images/services.jpg'
                                        }
                                        className="w-100"
                                        width=""
                                    />
                                </Link>
                            </div>
                        </div>
                        <div className="row mt-5 mb-5 pt-5">
                            <div className="col-12 col-md-6">
                                <div
                                    className="card border px-4 px-md-5 pb-5 pt-3"
                                    style={{
                                        maxWidth: '38rem',
                                        marginBottom: '3rem',
                                    }}
                                    data-aos="fade-up"
                                    data-aos-once="true"
                                    data-aos-duration="1000"
                                >
                                    <img
                                        src={process.env.PUBLIC_URL + '/html/app/dist/images/diagrams/DSO.png'}
                                        className="pt-4"
                                        alt="..."
                                        width="240"
                                    />
                                    <div className="card-body p-0 pt-3">
                                        <h5 className="card-title font-weight-bold">
                                            Increase buyer DPO and reduce vendor
                                            DSO
                                        </h5>
                                        <p className="card-text">
                                            Positive client supplier
                                            relationships are dependent on
                                            increasing the Days Payable Outstanding
                                            while reducing the Days Sales
                                            Outstanding.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div
                                    className="card border px-4 px-md-5 pb-5 pt-3"
                                    style={{
                                        maxWidth: '38rem',
                                        marginBottom: '3rem',
                                    }}
                                    data-aos="fade-up"
                                    data-aos-once="true"
                                    data-aos-duration="1000"
                                    data-aos-delay="100"
                                >
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/html/app/dist/images/diagrams/Get Paid Faster.png'
                                        }
                                        className="pt-4"
                                        alt="..."
                                        width="240"
                                    />
                                    <div className="card-body p-0 pt-3">
                                        <h5 className="card-title font-weight-bold">
                                          
                                        Early pay vendors quickly  and fairly to support vendor growth
                                           
                                        </h5>
                                        <p className="card-text">
                                            Automated invoicing of clients
                                            improves the chances of early and on-time payment by customers and/or
                                            third party providers within the
                                            client supplier ecosystem.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div
                                    className="card border px-4 px-md-5 pb-5 pt-3"
                                    style={{
                                        maxWidth: '38rem',
                                        marginBottom: '3rem',
                                    }}
                                    data-aos="fade-up"
                                    data-aos-once="true"
                                    data-aos-duration="1000"
                                    data-aos-delay="150"
                                >
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/html/app/dist/images/diagrams/Secure and Easy Communications.png'
                                        }
                                        className="pt-4"
                                        alt="..."
                                        width="240"
                                    />
                                    <div className="card-body p-0 pt-3">
                                        <h5 className="card-title font-weight-bold">
                                            Secure and easy buyer/vendor
                                            communication
                                        </h5>
                                        <p className="card-text">
                                            Cowry allows vendors to easily and
                                            securely communicate with clients
                                            consistently at all times, sharing
                                            critical data effortlessly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div
                                    className="card border px-4 px-md-5 pb-5 pt-3"
                                    style={{
                                        maxWidth: '38rem',
                                        marginBottom: '3rem',
                                    }}
                                    data-aos="fade-up"
                                    data-aos-once="true"
                                    data-aos-duration="1000"
                                    data-aos-delay="200"
                                >
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/html/app/dist/images/diagrams/Realtime Profitability Reporting.png '
                                        }
                                        className="pt-4"
                                        alt="..."
                                        width="240"
                                    />
                                    <div className="card-body p-0 pt-3">
                                        <h5 className="card-title font-weight-bold">
                                            Real-time profitability reporting
                                        </h5>
                                        <p className="card-text">
                                            Profitability reporting is
                                            streamlined and accurate as decision-making is dependent on real time
                                            data with no dependency on manual
                                            verification of invoiced orders.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div
                                    className="card border px-4 px-md-5 pb-5 pt-3"
                                    style={{
                                        maxWidth: '38rem',
                                        marginBottom: '3rem',
                                    }}
                                    data-aos="fade-up"
                                    data-aos-once="true"
                                    data-aos-duration="1000"
                                    data-aos-delay="200"
                                >
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/html/app/dist/images/diagrams/Business Analytics for Planning and Forecasting.png'
                                        }
                                        className="pt-4"
                                        alt="..."
                                        width="240"
                                    />
                                    <div className="card-body p-0 pt-3">
                                        <h5 className="card-title font-weight-bold">
                                            Business analytics for planning and
                                            forecasting
                                        </h5>
                                        <p className="card-text">
                                            Reporting from the platform allows for
                                            more accurate forecasting and
                                            business planning on
                                            vendor spend management and business
                                            cash flow needs.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div
                                    className="card border px-4 px-md-5 pb-5 pt-3"
                                    style={{
                                        maxWidth: '38rem',
                                        marginBottom: '3rem',
                                    }}
                                    data-aos="fade-up"
                                    data-aos-once="true"
                                    data-aos-duration="1000"
                                    data-aos-delay="200"
                                >
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/html/app/dist/images/diagrams/Business Analytics for Planning and Forecasting.png'
                                        }
                                        className="pt-4"
                                        alt="..."
                                        width="240"
                                    />
                                    <div className="card-body p-0 pt-3">
                                        <h5 className="card-title font-weight-bold">
                                        Optimize balance sheet
                                        </h5>
                                        <p className="card-text">
                                        Traditional banks are limited to their own balance sheet. Where as Cowry leverages the power of the financial markets to unlock capital within the supply chain so businesses can put it to work.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="" height="" viewBox="0 0 1920.904 187.469">
            <g id="Group_10635" data-name="Group 10635" transform="translate(721.766 -3677.74)">
              <g id="Group_10636" data-name="Group 10636" transform="translate(-721.766 3677.74)">
                <rect id="Rectangle_17926" data-name="Rectangle 17926" width="1920.904" height="182.049" transform="translate(0 5.42)" fill="#fbfbfd"/>
                <g id="_f8f8fcff" data-name="#f8f8fcff" transform="translate(1920.803 119.608) rotate(180)">
                  <path id="Path_9604" data-name="Path 9604" d="M385.9.258C399.259-.154,412.669.064,426.05.014c38.388.451,76.794,1.4,114.907,3.452,19.607.808,39.023,2.24,58.488,3.472,45.581,3.153,90.754,7.3,135.491,12.134,22.311,2.492,44.7,4.879,66.716,7.809,24.6,2.925,48.865,6.309,73.188,9.614,26.686,3.793,53.173,7.845,79.992,11.474,25.5,3.469,51,6.973,76.86,9.957,15.137,2.016,30.577,3.58,45.809,5.465,20.689,2.1,41.2,4.53,62.047,6.341,10.468,1.176,21.163,1.932,31.687,3,6.282.632,12.717.948,19.009,1.576,10.582,1.088,21.428,1.568,32.1,2.472,12.195,1.024,24.561,1.6,36.774,2.56,14.3.8,28.622,1.544,42.914,2.381,9.234.264,18.392.844,27.617,1.172q22.733.714,45.5,1.372c7.193.256,14.425-.02,21.609.292,8.589.352,17.215.164,25.832.24,7.917.019,15.843.051,23.953-.077,3.612-.057,7.418-.153,11.218-.3,21.115-.028,40.916.6,61.927-.152,32.8-1.547,66.517-5.652,98.484-9.031a1216.346,1216.346,0,0,0,126.94-20.1,973.033,973.033,0,0,0,96.211-25.848c13.932-4.5,27.275-9.309,40.732-14.056,6.652-2.66,13.646-5.161,20.517-7.725,6.206-2.232,11.9-4.7,18.24-6.877V119.608H0V68.616c5.476-2.588,9.927-5.525,15.469-8.085q10.2-5.046,21.8-9.581c10.069-3.652,20-7.4,30.833-10.654,30.425-9.569,64.476-16.931,99.7-22.8,12.005-2.072,24.456-3.62,36.774-5.329,30.178-3.9,61.076-6.8,92.4-8.673,10.724-.572,21.372-1.428,32.181-1.656Q357.455.658,385.9.258Z" fill="#fff"/>
                </g>
              </g>
            </g>
          </svg>
                </div>

                <div style={{ background: '#fbfbfd' }} className="pb-5">
                    <div className="container">
                        <div className="row stats">
                            <div className="col-12 col-md-6">
                                <div
                                    data-aos="fade-right"
                                    data-aos-once="true"
                                    data-aos-duration="1000"
                                    className="text-center mb-4 mb-md-0"
                                >
                                    {/* <div className="chart mb-5 mb-md-0"></div> */}

                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/html/app/dist/images/diagrams/DPO.png'
                                        }
                                        className=""
                                        alt="..."
                                        width=""
                                    />
                                  
                                </div>
                            </div>
                            <div
                                className="col-12 col-md-6 d-flex flex-column justify-content-center statement"
                                data-aos="fade-left"
                                data-aos-once="true"
                                data-aos-duration="1000"
                            >
                                <h3 className="mb-3 font-weight-bold">
                                Extend Days Payable Outstanding (DPO).
                                </h3>
                                <p className="mb-4">
                                With a click of the mouse, you can extend your payables an additional 30days and beyond to free up cash for your business without any recognition on your balance sheet.
                                </p>
                            </div>
                        </div>
                        <div className="row stats">
                           
                            <div
                                className="col-12 col-md-6 d-flex flex-column justify-content-center order-1 order-md-0 statement"
                                data-aos="fade-left"
                                data-aos-once="true"
                                data-aos-duration="1000"
                            >
                                <h3 className="mb-3 font-weight-bold">
                                Reduce Days Sales Outstanding (DSO).
                                </h3>
                                <p className="mb-4">
                                With a click of the mouse, you can reduce how long it takes you to collect payments on sales eliminating the need for other expensive forms of financing ie loss revenue from non collectible receivables results in the inability to grow a business leading to high cost alternative forms of financing for operational needs. 
                                </p>
                            </div>
                            <div className="col-12 col-md-6 order-0 order-md-1">
                                <div
                                    data-aos="fade-right"
                                    data-aos-once="true"
                                    data-aos-duration="1000"

                                    className="text-center mb-4 mb-md-0"
                                >
                                    {/* <div className="chart mb-5 mb-md-0"></div> */}

                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            '/html/app/dist/images/diagrams/DSO.png'
                                        }
                                        className=""
                                        alt="..."
                                        width=""
                                    />
                                  
                                </div>
                            </div>
                        </div>
                        <div className="row stats">
                        <div className="col-12 col-md-6">
                               <div
                                   data-aos="fade-right"
                                   data-aos-once="true"
                                   data-aos-duration="1000"

                                   className="text-center mb-4 mb-md-0"
                               >
                                   {/* <div className="chart mb-5 mb-md-0"></div> */}

                                   <img
                                       src={
                                           process.env.PUBLIC_URL +
                                           '/html/app/dist/images/diagrams/Invoicing Visibility.png'
                                       }
                                       className=""
                                       alt="..."
                                       width=""
                                   />
                                 
                               </div>
                           </div>
                           
                           <div
                               className="col-12 col-md-6 d-flex flex-column justify-content-center statement"
                               data-aos="fade-left"
                               data-aos-once="true"
                               data-aos-duration="1000"
                           >
                               <h3 className="mb-3 font-weight-bold">
                               Invoicing Visibility.
                               </h3>
                               <p className="mb-4">
                               With a click of the mouse, we provide real time visibility into the workflow of an invoice, the required expected action of a user, duplicate invoices becomes a thing of the past and provide visibility into 10years of historic invoices for improved customer relations.
                               </p>
                           </div>
                          
                       </div>

                        <div className="row">
                            <div
                                className="col-12 col-md-6 d-flex flex-column justify-content-center  order-1 order-md-0 statement"
                                data-aos="fade-right"
                                data-aos-once="true"
                                data-aos-duration="1000"
                            >
                                <h3 className="mb-3 font-weight-bold">
                               Dashboard
                                </h3>
                                <p className="mb-4">
                                Cowry leverages the power of automation and visualization tools to provide deeper insight at speed to clients. We combine high performance and ease of use to ensure data drives your success by providing new opportunities for hidden insights within multiple connected data sets.
                                </p>
                            </div>
                            <div
                                className="col-12 col-md-6 order-0 order-md-1"
                                data-aos="fade-left"
                                data-aos-once="true"
                                data-aos-duration="1000"
                            >
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <div
                                            className="card text-white bg-info"
                                            style={{
                                                // maxWidth: '18rem',
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="card-body d-flex justify-content-between">
                                                <div>
                                                    <p className="card-text">
                                                    Invoices Created
                                                    </p>
                                                    <h5 className="card-title">
                                                        25,000
                                                    </h5>
                                                </div>
                                                <div>+51%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <div
                                            className="card text-white bg-warning"
                                            style={{
                                                // maxWidth: '18rem',
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="card-body">
                                                <p className="card-text">
                                                

                                                Vendor Spend
                                                </p>
                                                <h5 className="card-title">
                                                   $ 10,200,532
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6"></div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <div
                                            className="card text-white bg-warning"
                                            style={{
                                                // maxWidth: '18rem',
                                                marginBottom: '2rem',
                                            }}
                                        >
                                            <div className="card-body">
                                                <p className="card-text">
                                                    Number of Vendors
                                                </p>
                                                <h5 className="card-title">
                                                    120
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" width="" height="" viewBox="0 0 1920.904 187.469">
                    <g id="Group_10635" data-name="Group 10635" transform="translate(721.766 -3677.74)">
                    <g id="Group_10636" data-name="Group 10636" transform="translate(-721.766 3677.74)">
                        <rect id="Rectangle_17926" data-name="Rectangle 17926" width="1920.904" height="182.049" transform="translate(0 5.42)" fill="#005eff"/>
                        <g id="_f8f8fcff" data-name="#f8f8fcff" transform="translate(1920.803 119.608) rotate(180)">
                        <path id="Path_9604" data-name="Path 9604" d="M385.9.258C399.259-.154,412.669.064,426.05.014c38.388.451,76.794,1.4,114.907,3.452,19.607.808,39.023,2.24,58.488,3.472,45.581,3.153,90.754,7.3,135.491,12.134,22.311,2.492,44.7,4.879,66.716,7.809,24.6,2.925,48.865,6.309,73.188,9.614,26.686,3.793,53.173,7.845,79.992,11.474,25.5,3.469,51,6.973,76.86,9.957,15.137,2.016,30.577,3.58,45.809,5.465,20.689,2.1,41.2,4.53,62.047,6.341,10.468,1.176,21.163,1.932,31.687,3,6.282.632,12.717.948,19.009,1.576,10.582,1.088,21.428,1.568,32.1,2.472,12.195,1.024,24.561,1.6,36.774,2.56,14.3.8,28.622,1.544,42.914,2.381,9.234.264,18.392.844,27.617,1.172q22.733.714,45.5,1.372c7.193.256,14.425-.02,21.609.292,8.589.352,17.215.164,25.832.24,7.917.019,15.843.051,23.953-.077,3.612-.057,7.418-.153,11.218-.3,21.115-.028,40.916.6,61.927-.152,32.8-1.547,66.517-5.652,98.484-9.031a1216.346,1216.346,0,0,0,126.94-20.1,973.033,973.033,0,0,0,96.211-25.848c13.932-4.5,27.275-9.309,40.732-14.056,6.652-2.66,13.646-5.161,20.517-7.725,6.206-2.232,11.9-4.7,18.24-6.877V119.608H0V68.616c5.476-2.588,9.927-5.525,15.469-8.085q10.2-5.046,21.8-9.581c10.069-3.652,20-7.4,30.833-10.654,30.425-9.569,64.476-16.931,99.7-22.8,12.005-2.072,24.456-3.62,36.774-5.329,30.178-3.9,61.076-6.8,92.4-8.673,10.724-.572,21.372-1.428,32.181-1.656Q357.455.658,385.9.258Z" fill="#fbfbfd"/>
                        </g>
                    </g>
                    </g>
                </svg>
                </div>
            </div>
        </Layout>
    );
}
