import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { postData } from '../../api';

const Footer = () => {
  const [email, setEmail] = useState('');

  const submitEmail = () => {
    const payload = { email: email };
    postData(
      'https://api.cowryllc.com/api/ContactAndNewsletter/Newsletter',
      payload,
      'POST'
    ).then(res => setEmail(''));
  };

  return (
    <footer className="pt-5 pt-md-0">
      <div className="container">
        <div className="row mb-4 mb-md-4">
          <div
            className="col-12 pl-lg-3 pl-xl-4 ml-xl-1"
            style={{ lineHeight: 2 }}
          >
            <svg
              width="180"
              height="35"
              viewBox="0 0 365 69"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Cowry-Logo-Reverse-Horizontal">
                <g id="Group 2">
                  <g id="Group">
                    <path
                      id="Fill 25"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.6334 1.33842C11.5733 3.23583 -3.84043 31.3773 2.55565 50.5158C5.98561 60.7808 15.6716 67.691 26.3969 67.691C26.4451 67.691 26.4933 67.691 26.5415 67.6905C16.6926 65.5891 8.87664 57.7208 6.90767 47.7786C5.57862 41.0663 6.67729 32.9981 10.1732 23.7978C13.0698 16.1747 17.0189 9.35992 19.849 4.94904C16.0167 2.85016 13.2002 1.58759 12.6334 1.33842ZM26.3968 67.9447C15.5625 67.9452 5.78006 60.9656 2.31458 50.596C0.402961 44.8759 -0.703312 34.6332 4.88134 18.3258C8.21792 8.58147 12.4273 1.18669 12.4695 1.11362L12.5253 1.01517L12.6293 1.05982C12.6603 1.07302 15.7375 2.40105 20.0901 4.79274L20.2093 4.8582L20.1357 4.97238C5.86785 27.1511 5.87952 41.2805 7.15631 47.7288C9.18109 57.9538 17.4513 65.9626 27.7355 67.6575L28.891 67.8483L27.7213 67.9102C27.2788 67.9331 26.8363 67.9447 26.3968 67.9447Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 27"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.9639 2.32164C10.593 6.73659 -2.62388 32.6153 3.27743 50.2745C5.85941 58.0002 12.0266 63.7599 19.563 65.9567C12.7163 62.3268 7.71521 55.7755 6.16084 47.926C4.80388 41.0717 5.91422 32.8634 9.4619 23.5271C12.2398 16.2165 15.9752 9.64994 18.7683 5.22891C16.1001 3.79126 14.0063 2.79866 12.9639 2.32164ZM26.3971 68.7059C15.1323 68.7064 5.16471 61.5258 1.59267 50.8376C-0.355998 45.0058 -1.49272 34.5886 4.16095 18.0792C7.53813 8.21763 11.7668 0.808644 11.8095 0.734555L12.2027 0.0484619L12.9299 0.36106C12.9523 0.370195 16.0559 1.70737 20.4572 4.12594L21.2894 4.58368L20.7769 5.38344C6.6526 27.3384 6.64854 41.2455 7.903 47.5817C9.86638 57.4965 17.8864 65.2628 27.8596 66.907L35.9369 68.2406L33.3945 68.3746L33.4265 68.3817L33.2219 68.3837L27.7616 68.6704C27.3105 68.6943 26.8517 68.7059 26.3971 68.7059Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 29"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.0719 5.07159C5.86895 27.1925 5.88164 41.2904 7.15639 47.7291C9.18118 57.954 17.4513 65.9623 27.7356 67.6578C28.1462 67.6344 28.5648 67.6009 28.9799 67.5578C22.7244 66.075 17.3356 62.336 13.7453 56.968C10.0119 51.3854 8.65088 44.6777 9.91396 38.0807L10.0388 38.1045L9.91396 38.0807C12.027 27.0418 21.3405 16.965 28.8353 10.4208C26.0787 8.55483 23.1314 6.75587 20.0719 5.07159ZM27.7214 67.91C17.3067 66.1958 8.95283 58.1063 6.90774 47.7783C5.57819 41.0666 6.67735 32.9979 10.1733 23.7976C13.0953 16.1085 17.0875 9.24144 19.9222 4.83461L19.9866 4.73566L20.0902 4.79249C23.2446 6.52447 26.2802 8.37926 29.1134 10.3036L29.2499 10.3959L29.1256 10.504C21.6405 17.0188 12.2747 27.0996 10.1636 38.1284C8.91274 44.6595 10.2601 51.3001 13.9559 56.8264C17.6487 62.3466 23.2613 66.1273 29.7619 67.4715L30.4912 67.6228L29.7528 67.7217C29.0961 67.81 28.4131 67.8734 27.7214 67.91Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 31"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.3286 6.08402C6.6499 27.6391 6.66259 41.3173 7.90334 47.581C9.18266 54.0425 13.0338 59.5911 18.3221 63.1277C16.3313 61.5023 14.5735 59.5749 13.1125 57.3908C9.40848 51.8523 7.97185 45.2385 9.03397 38.6876L9.02332 38.6856L9.16439 37.9478C9.16541 37.9442 9.16591 37.9407 9.16642 37.9376L9.30953 37.1901L9.32018 37.1921C11.6591 26.5871 20.3413 16.9605 27.591 10.5035C25.2815 8.97201 22.8446 7.4897 20.3286 6.08402ZM27.6793 68.6748L27.5981 68.6611C16.8824 66.8976 8.26771 58.5651 6.16123 47.9261C4.80376 41.0717 5.9146 32.8635 9.46178 23.5271C12.4071 15.7771 16.4278 8.86038 19.2817 4.42311L19.7329 3.72687L20.457 4.12574C23.6287 5.86685 26.6851 7.73381 29.5412 9.67334L30.4937 10.3229L29.6254 11.0785C22.2306 17.5142 12.9811 27.4564 10.9106 38.2715C9.69826 44.6042 11.0045 51.0439 14.5887 56.4033C18.1694 61.757 23.6129 65.4229 29.9162 66.7261L35.0157 67.7832L29.8543 68.4759C29.1768 68.5672 28.4724 68.6327 27.762 68.6702L27.6793 68.6748Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 33"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M29.0521 10.5685C21.5787 17.0853 12.2677 27.1357 10.1633 38.1284C8.91238 44.6595 10.2602 51.3001 13.9561 56.8264C17.6443 62.3406 23.2493 66.1191 29.7403 67.467C30.7887 67.3259 31.8315 67.1183 32.847 66.8484C32.6678 66.7956 32.4887 66.7403 32.3101 66.6814C19.0099 62.2994 11.7537 47.9138 16.1351 34.6132C18.4928 27.4534 24.1962 20.3412 33.0865 13.4717C31.7945 12.4887 30.438 11.5124 29.0521 10.5685ZM29.7317 67.7248L29.7104 67.7202C23.1443 66.3622 17.4749 62.5441 13.7455 56.968C10.0116 51.3854 8.65106 44.6777 9.91414 38.0807C12.0394 26.9809 21.4437 16.8539 28.9588 10.3127L29.0329 10.2483L29.1136 10.3036C30.5796 11.2992 32.0122 12.3314 33.3722 13.3702L33.5031 13.4707L33.3722 13.5716C24.4479 20.4407 18.7293 27.5467 16.3762 34.6923L16.2554 34.6528L16.3762 34.6923C12.0384 47.8605 19.2221 62.1026 32.3893 66.4409C32.7085 66.5459 33.0282 66.6383 33.3479 66.7301L33.7746 66.8524L33.3479 66.9737C32.1787 67.3071 30.9689 67.5588 29.753 67.7217L29.7317 67.7248Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 35"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M29.1097 11.5309C21.8235 17.9605 12.9347 27.6992 10.911 38.2712C9.69812 44.6044 11.0043 51.0436 14.5891 56.4035C18.1408 61.7141 23.5276 65.3648 29.7679 66.6949C29.8668 66.6812 29.9658 66.6664 30.0642 66.6512C17.6552 61.4127 11.1251 47.3894 15.4122 34.3749C17.7475 27.2836 23.2713 20.2583 31.8358 13.4836C30.9482 12.8234 30.0358 12.1698 29.1097 11.5309ZM29.7247 68.4933L29.5547 68.4649C22.7938 67.0668 16.9539 63.134 13.1129 57.391C9.26677 51.6409 7.86566 44.7323 9.1663 37.9373C11.3327 26.6244 20.8532 16.3584 28.4596 9.73851L28.9737 9.28839L29.5415 9.67356C31.0208 10.6788 32.465 11.7191 33.8342 12.766L34.7542 13.4669L33.8367 14.1743C25.0352 20.9494 19.4039 27.9321 17.099 34.9306C12.8926 47.6994 19.8591 61.5106 32.6279 65.7175C32.937 65.8195 33.2475 65.9093 33.5581 65.9986L36.5461 66.8552L33.5561 67.7057C32.3514 68.0493 31.1055 68.3086 29.8541 68.4761L29.7247 68.4933Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 37"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M40.144 67.7034C40.1851 67.7039 40.2262 67.7039 40.2674 67.7039C51.0378 67.7039 60.737 60.7511 64.138 50.4273C70.4514 31.2608 54.9199 3.1853 53.8517 1.29296C53.2854 1.54517 50.4654 2.82246 46.631 4.94519C49.485 9.33882 53.4711 16.1277 56.4063 23.7255C59.9438 32.8822 61.0866 40.9255 59.8022 47.6332C57.883 57.6581 50.0569 65.5893 40.144 67.7034ZM40.2674 67.9576C39.8325 67.9576 39.3976 67.9465 38.9597 67.9241L37.7859 67.8627L38.9454 67.6719C49.2977 65.9653 57.5796 57.8936 59.5531 47.5854C60.7873 41.1391 60.7121 27.0306 46.3443 4.96954L46.2697 4.85486L46.3895 4.78889C50.7425 2.37335 53.8238 1.02705 53.8548 1.01385L53.9588 0.968689L54.0151 1.06714C54.0572 1.14021 58.2976 8.51723 61.6758 18.2469C64.7957 27.2351 67.7979 40.1277 64.3791 50.5064C60.9441 60.9338 51.1459 67.9576 40.2674 67.9576Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 39"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M47.7134 5.21928C50.5318 9.6256 54.3023 16.1683 57.1162 23.451C60.706 32.7427 61.861 40.9271 60.5497 47.7763C59.0344 55.6938 54.0323 62.3 47.1521 65.9567C54.6996 63.7412 60.8577 57.952 63.4148 50.1888C69.241 32.5011 55.915 6.68179 53.5254 2.2775C52.4805 2.76163 50.3781 3.76894 47.7134 5.21928ZM40.2673 68.7189C39.8258 68.7189 39.3727 68.7067 38.921 68.6844L30.7128 68.2581L38.8216 66.9204C48.8607 65.2661 56.8919 57.4379 58.805 47.4424C60.0179 41.1087 59.928 27.2214 45.7063 5.38471L45.1867 4.58444L46.0205 4.12315C50.3872 1.6995 53.5193 0.329345 53.5507 0.316151L54.2789 0L54.6748 0.687108C54.7169 0.760183 58.9999 8.21941 62.3948 17.9972C65.5533 27.0961 68.5874 40.1638 65.1021 50.745C61.5606 61.4951 51.5802 68.7189 40.2673 68.7189Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 41"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M37.6379 67.5646C38.086 67.6123 38.5331 67.6478 38.9726 67.6702C49.2979 65.9656 57.5798 57.8933 59.5533 47.5857C60.7849 41.149 60.7113 27.0714 46.4084 5.06876C43.3672 6.76065 40.4407 8.56368 37.7074 10.4306C45.2149 16.9145 54.5568 26.9131 56.7348 37.9129C59.3894 51.3196 50.8594 64.4579 37.6379 67.5646ZM38.9862 67.9224C38.2712 67.8884 37.5663 67.8219 36.8645 67.7265L36.1251 67.626L36.8564 67.4763C50.3565 64.7187 59.1625 51.4785 56.4861 37.9627C54.3096 26.9715 44.9149 16.9703 37.4166 10.5154L37.2918 10.4078L37.4283 10.3149C40.2376 8.38911 43.2525 6.53027 46.3891 4.78916L46.4921 4.73181L46.5566 4.83077C49.4156 9.22034 53.4449 16.062 56.406 23.7252C59.9435 32.8819 61.0863 40.9257 59.8024 47.6334C57.8086 58.0451 49.4435 66.1985 38.9862 67.9224Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 43"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M38.9532 10.5075C46.3845 17.0548 55.3306 26.9052 57.4817 37.7655C58.8022 44.4346 57.4873 51.2711 53.7793 57.0146C52.1924 59.4718 50.2438 61.6128 48.0145 63.3793C53.522 59.8311 57.5269 54.1211 58.8052 47.4423C60.0038 41.1807 59.9313 27.5203 46.1572 6.0819C43.6594 7.49164 41.2429 8.97597 38.9532 10.5075ZM39.0304 68.6864L38.9502 68.6828C38.2336 68.6483 37.4973 68.5808 36.7625 68.4808L31.5884 67.777L36.7036 66.7306C49.7952 64.0562 58.3348 51.2174 55.7391 38.1106C53.6053 27.3325 44.3278 17.4694 36.9203 11.0921L36.046 10.339L36.998 9.68695C39.825 7.74894 42.8602 5.87741 46.0197 4.12361L46.7438 3.72119L47.195 4.41591C50.0733 8.83542 54.1315 15.7263 57.1164 23.4509C60.7057 32.7426 61.8612 40.927 60.5499 47.7763C58.4972 58.4995 49.881 66.8975 39.1105 68.6737L39.0304 68.6864Z"
                      fill="#FFFFFE"
                    />
                    <path
                      id="Fill 45"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M33.7769 66.8485C34.7923 67.1195 35.8331 67.3291 36.877 67.4722C50.3649 64.7039 59.1608 51.4708 56.486 37.9626C54.317 27.0074 44.9761 17.0357 37.4905 10.5793C36.1229 11.5186 34.7822 12.4904 33.5034 13.4708C42.3921 20.2906 48.1067 27.3642 50.4913 34.4991C52.6414 40.9333 52.1568 47.8191 49.1272 53.8894C46.0976 59.9592 40.886 64.4862 34.4513 66.6359C34.2402 66.7064 34.02 66.7754 33.7769 66.8485ZM36.8856 67.7295L36.8643 67.7264C35.6535 67.5615 34.4467 67.3083 33.278 66.9739L32.8513 66.8521L33.2785 66.7303C33.6992 66.61 34.0463 66.5034 34.3711 66.3948C40.7413 64.2665 45.9012 59.7851 48.9003 53.7757C51.8995 47.7663 52.379 40.949 50.2502 34.5793C47.8707 27.4591 42.1399 20.3911 33.2176 13.5718L33.0867 13.4713L33.2171 13.3703C34.565 12.3336 35.9813 11.3059 37.4281 10.3149L37.5088 10.2596L37.5824 10.323C45.1106 16.8038 54.5444 26.8516 56.7346 37.9128C59.4384 51.5657 50.5435 64.9389 36.9069 67.7254L36.8856 67.7295Z"
                      fill="#FFFFFE"
                    />
                  </g>
                  <path
                    id="Fill 47"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.5586 66.6563C36.656 66.6715 36.754 66.6862 36.8514 66.6999C49.8577 63.9596 58.3243 51.1633 55.7393 38.1103C53.6531 27.5733 44.7364 17.9112 37.4375 11.5415C36.5256 12.1763 35.6269 12.8244 34.7541 13.4785C43.3165 20.2044 48.8519 27.1912 51.2132 34.2577C53.4273 40.8846 52.9289 47.977 49.8085 54.2295C46.9865 59.8831 42.3254 64.2372 36.5586 66.6563ZM36.9311 68.4973L36.7596 68.4806C35.5147 68.3111 34.272 68.0503 33.0688 67.7057L30.0778 66.8521L33.0693 65.9981C33.4778 65.8819 33.8152 65.7789 34.1304 65.6733C40.3072 63.6089 45.3108 59.2635 48.2196 53.4358C51.1279 47.6086 51.5928 40.9973 49.5284 34.821C47.1981 27.8474 41.5551 20.9017 32.7557 14.1763L31.8351 13.4749L32.7526 12.768C34.1091 11.7242 35.5371 10.6874 36.9981 9.68672L37.5619 9.30206L38.0785 9.74609C45.698 16.3046 55.249 26.4899 57.4814 37.7653C60.2663 51.8266 51.1046 65.6012 37.059 68.471L36.9311 68.4973Z"
                    fill="#FFFFFE"
                  />
                </g>
                <g id="Group 3">
                  <path
                    id="Fill 49"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M99.8126 54.6276C93.6542 49.2232 92.0205 42.1858 92.0205 36.0902C92.0205 31.8168 93.34 24.4017 99.3721 18.8717C103.897 14.7252 109.867 12.2112 119.23 12.2112C123.125 12.2112 125.45 12.4625 128.341 12.8396C130.729 13.1537 132.74 13.7192 134.625 13.9077C135.317 13.9706 135.568 14.2847 135.568 14.6618C135.568 15.1644 135.379 15.9191 135.253 18.1183C135.128 20.1918 135.191 23.6484 135.065 24.905C135.002 25.8469 134.877 26.3496 134.311 26.3496C133.808 26.3496 133.682 25.8469 133.682 24.9672C133.62 23.0194 132.803 20.8202 131.232 19.1865C129.158 16.9873 124.256 15.2901 118.475 15.2901C113.008 15.2901 109.427 16.6731 106.662 19.1865C102.075 23.3964 100.881 29.3663 100.881 35.4612C100.881 50.4177 112.254 57.8322 120.675 57.8322C126.268 57.8322 129.661 57.2045 132.175 54.3135C133.243 53.119 134.059 51.2974 134.311 50.1658C134.5 49.1611 134.625 48.9091 135.128 48.9091C135.568 48.9091 135.694 49.3496 135.694 49.8522C135.694 50.6062 134.939 56.0728 134.311 58.2092C133.996 59.278 133.746 59.5916 132.678 60.0321C130.164 61.0374 125.388 61.4772 121.366 61.4772C112.757 61.4772 105.531 59.5916 99.8126 54.6276Z"
                    fill="#FFFFFE"
                  />
                  <path
                    id="Fill 51"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M184.829 38.038C184.829 23.9625 176.722 15.2278 166.418 15.2278C159.191 15.2278 150.959 19.2492 150.959 34.8963C150.959 47.9665 158.185 58.3348 170.25 58.3348C174.649 58.3348 184.829 56.1984 184.829 38.038ZM142.287 36.8442C142.287 26.0988 149.388 12.2112 168.24 12.2112C183.886 12.2112 193.627 21.3228 193.627 35.7131C193.627 50.1029 183.573 61.4772 167.611 61.4772C149.577 61.4772 142.287 47.9665 142.287 36.8442Z"
                    fill="#FFFFFE"
                  />
                  <path
                    id="Fill 53"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M200.508 19.2494C199.754 16.9239 199.063 15.4159 198.183 14.7876C197.492 14.3477 196.235 14.2221 195.67 14.2221C195.041 14.2221 194.664 14.1599 194.664 13.6565C194.664 13.2167 195.167 13.091 196.047 13.091C199.315 13.091 202.771 13.2789 203.336 13.2789C203.902 13.2789 206.666 13.091 210.311 13.091C211.191 13.091 211.631 13.2789 211.631 13.6565C211.631 14.1599 211.128 14.2221 210.5 14.2221C209.997 14.2221 209.368 14.2221 208.929 14.4734C208.363 14.7247 208.238 15.1646 208.238 15.8557C208.238 16.6726 208.865 18.809 209.62 21.6372C210.688 25.7842 216.532 44.825 217.664 48.3437H217.852L230.043 15.5416C230.671 13.7822 231.111 13.2789 231.614 13.2789C232.305 13.2789 232.619 14.2221 233.436 16.2328L246.506 47.9032H246.695C247.826 43.6933 252.853 27.1044 255.053 19.7515C255.492 18.3063 255.681 16.9874 255.681 16.1077C255.681 15.1017 255.115 14.2221 252.665 14.2221C252.036 14.2221 251.597 14.0336 251.597 13.6565C251.597 13.2167 252.099 13.091 253.042 13.091C256.372 13.091 259.011 13.2789 259.577 13.2789C260.017 13.2789 262.719 13.091 264.793 13.091C265.484 13.091 265.924 13.2167 265.924 13.5937C265.924 14.0336 265.609 14.2221 265.044 14.2221C264.416 14.2221 263.536 14.2849 262.845 14.5362C261.337 15.1017 260.646 17.6151 259.325 21.1973C256.436 29.1151 249.837 49.0977 246.758 58.5235C246.067 60.7234 245.752 61.4767 244.936 61.4767C244.307 61.4767 243.93 60.7234 242.988 58.3979L229.917 26.8524H229.791C228.597 30.057 220.24 52.3651 217.538 58.1465C216.343 60.7234 216.029 61.4767 215.275 61.4767C214.648 61.4767 214.396 60.7856 213.83 59.1519L200.508 19.2494Z"
                    fill="#FFFFFE"
                  />
                  <path
                    id="Fill 55"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M284.897 36.2786C284.897 36.9698 285.022 37.2211 285.462 37.4096C286.782 37.8495 288.667 38.038 290.238 38.038C292.751 38.038 293.568 37.7873 294.699 36.9698C296.584 35.5874 298.407 32.6971 298.407 27.544C298.407 18.6209 292.5 16.044 288.792 16.044C287.221 16.044 286.09 16.1075 285.462 16.296C285.022 16.421 284.897 16.673 284.897 17.1757V36.2786ZM277.482 31.3147C277.482 21.7626 277.482 20.0032 277.356 17.9919C277.23 15.8555 276.727 14.8502 274.654 14.411C274.151 14.2846 273.083 14.2225 272.203 14.2225C271.512 14.2225 271.135 14.0961 271.135 13.6569C271.135 13.2165 271.575 13.0908 272.517 13.0908C275.848 13.0908 279.807 13.2793 281.126 13.2793C283.263 13.2793 288.038 13.0908 289.986 13.0908C293.945 13.0908 298.156 13.4684 301.549 15.7933C303.308 16.9872 305.822 20.1917 305.822 24.4023C305.822 29.052 303.874 33.3254 297.527 38.4785C303.12 45.5159 307.456 51.1088 311.163 55.0046C314.682 58.6496 317.259 59.0888 318.201 59.2779C318.893 59.403 319.458 59.4664 319.961 59.4664C320.463 59.4664 320.715 59.6549 320.715 59.9685C320.715 60.4711 320.275 60.5975 319.521 60.5975H313.551C310.032 60.5975 308.461 60.2826 306.828 59.403C304.125 57.9584 301.737 55.0046 298.218 50.1657C295.705 46.7104 292.814 42.4364 291.997 41.4945C291.683 41.1175 291.306 41.054 290.866 41.054L285.399 40.929C285.085 40.929 284.897 41.054 284.897 41.431V42.3107C284.897 48.155 284.897 53.1195 285.211 55.7586C285.399 57.5814 285.776 58.9638 287.661 59.2145C288.604 59.3408 289.986 59.4664 290.741 59.4664C291.243 59.4664 291.495 59.6549 291.495 59.9685C291.495 60.409 291.054 60.5975 290.238 60.5975C286.593 60.5975 281.943 60.409 281.001 60.409C279.807 60.409 275.848 60.5975 273.334 60.5975C272.517 60.5975 272.078 60.409 272.078 59.9685C272.078 59.6549 272.328 59.4664 273.083 59.4664C274.026 59.4664 274.78 59.3408 275.345 59.2145C276.602 58.9638 276.916 57.5814 277.167 55.7586C277.482 53.1195 277.482 48.155 277.482 42.3742V31.3147Z"
                    fill="#FFFFFE"
                  />
                  <path
                    id="Fill 57"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M335.832 44.322C335.832 40.9289 335.204 39.5466 334.387 37.7237C333.947 36.719 325.526 22.8935 323.327 19.6261C321.756 17.3006 320.185 15.7297 318.991 15.0392C318.174 14.5994 317.106 14.2224 316.352 14.2224C315.849 14.2224 315.409 14.096 315.409 13.6568C315.409 13.2792 315.849 13.0907 316.603 13.0907C317.734 13.0907 322.447 13.2792 324.521 13.2792C325.84 13.2792 328.417 13.0907 331.245 13.0907C331.873 13.0907 332.187 13.2792 332.187 13.6568C332.187 14.0339 331.748 14.096 330.805 14.348C330.239 14.4731 329.925 14.9757 329.925 15.479C329.925 15.9817 330.239 16.7357 330.679 17.6154C331.559 19.5005 340.356 34.6448 341.362 36.4042C341.99 34.8962 349.908 21.0713 350.788 19.1234C351.417 17.7411 351.667 16.6729 351.667 15.8561C351.667 15.2277 351.417 14.5365 350.348 14.2846C349.782 14.1595 349.217 14.0339 349.217 13.6568C349.217 13.2164 349.531 13.0907 350.285 13.0907C352.359 13.0907 354.369 13.2792 356.192 13.2792C357.574 13.2792 361.722 13.0907 362.915 13.0907C363.607 13.0907 364.047 13.2164 364.047 13.5934C364.047 14.0339 363.544 14.2224 362.853 14.2224C362.224 14.2224 361.031 14.4731 360.088 14.9757C358.768 15.6047 358.077 16.3587 356.695 18.0553C354.621 20.5686 345.824 35.336 344.692 38.0379C343.75 40.3006 343.75 42.2484 343.75 44.322V49.4744C343.75 50.4797 343.75 53.1194 343.939 55.7585C344.064 57.5813 344.63 58.9637 346.515 59.215C347.395 59.3407 348.776 59.4663 349.657 59.4663C350.348 59.4663 350.662 59.6548 350.662 59.9684C350.662 60.4089 350.159 60.5974 349.342 60.5974C345.383 60.5974 341.425 60.4089 339.728 60.4089C338.157 60.4089 334.198 60.5974 331.873 60.5974C330.994 60.5974 330.491 60.4711 330.491 59.9684C330.491 59.6548 330.805 59.4663 331.496 59.4663C332.376 59.4663 333.13 59.3407 333.695 59.215C334.952 58.9637 335.518 57.5813 335.643 55.7585C335.832 53.1194 335.832 50.4797 335.832 49.4744V44.322Z"
                    fill="#FFFFFE"
                  />
                </g>
              </g>
            </svg>

            <p className="mb-2">
              Honesty, Integrity, Innovation and Transparency (HIIT)
            </p>
          </div>
        </div>
        <div className="row justify-content-around">
          <div className="col-12 col-md-12 col-lg-4 pt-0 pt-md-0">
            <div className="row">
              <div className="col-6">
                {/* <p className="mb-1 font-weight-bold">USA</p> */}
                <h5 className="mb-1">USA</h5>
                <p className="mb-2" style={{ lineHeight: 2 }}>
                  99 Wall Street #2008 <br />
                  New York NY 10005 <br />
                  {/* Phone: +1 302 442 0850 <br/> */}
                  <a href="tel:+1-302-442-0850" className="text-white">
                    +1-302-442-0850
                  </a>
                </p>
              </div>
              <div className="col-6">
                {/* <p className="mb-1 font-weight-bold"></p> */}
                <h5 className="mb-1">Ghana</h5>
                <p className="mb-2" style={{ lineHeight: 2 }}>
                  {/* Y16, <br/> */}
                  Tema - Community 9 <br />
                  Accra, Ghana <br />
                  {/* Phone: +233 244 630708 / +233 55 753 8447 <br/> */}
                  <a href="tel:+233 244 630708" className="text-white">
                    +233 244 630708
                  </a>
                  <a
                    href="tel:+233  55 753 8447"
                    className="text-white d-block"
                  >
                    +233 557 538 447
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-6 col-lg-2 pt-5 pt-md-0">
            <h5>Company</h5>
            <Link to="/about" className="d-block text-white mb-2">
              About
            </Link>
            <Link to="/services" className="d-block text-white mb-2">
              Services
            </Link>
            <Link to="/terms-of-service" className="d-block text-white mb-2">
              Privacy Policy
            </Link>
          </div>
          <div className="col-6 col-md-6 col-lg-3 col-xl-2 pt-5 pt-md-0 mt-0 mt-md-4 mt-lg-0">
            <h5>Lets Talk</h5>
            <p className="mb-2">
              <a href="mailto: info@cowryllc.com" className="text-white">
                info@cowryllc.com
              </a>
            </p>
            <p className="mb-2">
              <a href="mailto: support@cowryllc.com" className="text-white">
                support@cowryllc.com
              </a>
            </p>
            <p className="mb-2">
              <a href="mailto:  press@cowryllc.com" className="text-white">
                press@cowryllc.com
              </a>
            </p>

            <p className="mb-2">
              {/* <a href="tel:+233 244 630708" className="text-white">+ 233 244 630708</a> */}
            </p>
          </div>
          <div className="col-12 col-md-12 col-lg-3 pt-5 pt-md-0 mt-0 mt-md-4 mt-lg-0">
            <h5>Sign up to our newsletter</h5>

            <form className="newsletter mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="E-mail Address"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
              <button
                onClick={e => {
                  e.preventDefault();
                  submitEmail();
                }}
              >
                Subscribe!
              </button>

              <button
                className="d-none"
                onClick={e => {
                  e.preventDefault();
                  submitEmail();
                }}
              >
                Thank you! <i class="fas ml-2 fa-check"></i>
              </button>
            </form>

            <div className="d-flex mt-3">
              <a
                href="https://www.linkedin.com/company/cowryllc" target="_blank" rel="noopener noreferrer"
                className="d-block mr-4"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/html/app/dist/images/linkedin.png'
                  }
                  alt="twitter"
                  srcSet=""
                  width="25"
                  className=""
                />
              </a>

              <a
                href="https://www.instagram.com/cowryllc" target="_blank" rel="noopener noreferrer"
                className="d-block mr-4 mt-1"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/html/app/dist/images/instagram2.png'
                  }
                  alt="instagram"
                  srcSet=""
                  width="22"
                  className=""
                />
              </a>

              <a
                href="https://www.facebook.com/cowryLLC" target="_blank" rel="noopener noreferrer"
                className="d-block mr-4"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/html/app/dist/images/facebook.png'
                  }
                  alt="facebook"
                  srcSet=""
                  width="14"
                  className=""
                />
              </a>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <p className="mb-0 pb-4 pt-4 text-center">
            Copyright <span>©</span> {new Date().getFullYear()} Cowry LLC. All
            rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
