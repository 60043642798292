export const postData = async (url, data, method, headers) => {
    // let token = localStorage.getItem('token');
    let request;
    // console.log('token 1=>', token);
    // token = token && token.substring(1, token.length - 1);
    if (data && method) {
        request = await fetch(url, {
            method: method || 'GET',
            mode: 'cors',
            headers: (headers && {
                ...headers,
                // Authorization: `Bearer ${token}`,
            }) || {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            },
            referrer: 'no-referrer',
            body: JSON.stringify(data),
        }).then((response) => response.json());
        
        return request;
    }
    request = await fetch(url, {
        method: method || 'GET',
        mode: 'cors',
        headers: {
            ...headers,
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${token}`,
        },
    }).then((response) => response.json());

    return request;
};