import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomePage from './components/pages/home';
import AboutPage from './components/pages/about';
import ServicesPage from './components/pages/services';
import ContactPage from './components/pages/contact';
import TermsPage from './components/pages/terms-of-service';

function App() {
  return (
    <Router>
      <Route path="/" exact component={HomePage} />
      <Route path="/about" exact component={AboutPage} />
      <Route path="/services" exact component={ServicesPage} />
      <Route path="/contact" exact component={ContactPage} />
      <Route path="/terms-of-service" exact component={TermsPage} />
    </Router>
  );
}

export default App;
