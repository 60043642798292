import React from 'react';
import Layout from '../layout';

export default function AboutPage() {
  return (
    <Layout>
      <div className="">
        <div className="row hero-section justify-content-center no-gutters">
          <div className="col-11 col-md-8 col-lg-8 col-xl-6">
            <div
              className="h-100 d-flex flex-column justify-content-center statement text-center"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              <h1 className="mb-4 font-weight-bold">Who we are</h1>
              <p className="mb-4">
                Cowry is a technology startup provider of working capital
                finance solutions and an electronic platform for streamlining
                the supply chain management process. Cowry was formed on the
                premise that access to capital is the greatest challenge Africa
                businesses face.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 d-none">
            <div
              data-aos="fade-left"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              <img
                src={process.env.PUBLIC_URL + '/html/app/dist/images/1.svg'}
                className="d-none d-md-block m-auto w-100"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="box-section">
        <div className="container">
          <div className="mt-5">
            <h3
              className="text-center web-title mb-4 font-weight-bold"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              The History of Cowry
            </h3>
            <div
              className="statement"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1500"
            >
              <div className="row justify-content-center">
                <div className="col-12 col-md-10">
                  <p className="text-center">
                    Cowry shells were the longest lasting, extremely successful
                    and most universal form of money used around the world from
                    around the 13th century BC until the 20th century as both a
                    medium of exchange and a store of value. Their use was most
                    prolific in trade networks across Africa and Asia and gave
                    businesses easy access to capital by allowing them to avoid
                    bartering --this event was a financial revolution at the time.
                  </p>

                  <p className="text-center">
                    Cowry LLC offers a highly innovative and well-integrated supply chain management system, which provides immediate access to readily avaliable and fairly priced capital.
                    
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="mt-5">
            <h3
              className="text-center web-title mb-4 font-weight-bold"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              Mission
            </h3>
            <div
              className="statement"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1500"
            >
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <p className="text-center">
                    Our mission is optimizing the balance sheet usage of our clients by
                    unlocking $10 billion of capital from the supply chain
                    ecosystem by 2025 leveraging our well integrated, highly innovative, data driven, dynamic and resilient digital Technology.
                   
                   
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="">
            <h3
              className="text-center web-title mb-4 font-weight-bold"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              Vision
            </h3>
            <div
              className="statement"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1500"
            >
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <p className="text-center">
                    Creating innovative financial solutions that ensures capital
                    is easily accessible, better priced and readily available.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="mt-5">
            <h3
              className="text-center web-title mb-4 font-weight-bold"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              Our Amazing Team
            </h3>
            <div
              className="statement"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-duration="1500"
            ></div>
          </div>
        </div>
      </div>

      <div style={{}}>
        <div className="container">
          <div className="mt-5">
            <div className="row d-none  justify-content-center">
              <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0">
                <div
                  className="card py-0 bg-transparent shadow-sm"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="1000"
                >
                  {/* <img
                    src={process.env.PUBLIC_URL + '/html/app/dist/images/8.png'}
                    className="m-auto"
                    alt="..."
                    width="200"
                  /> */}
                  {/* <div className="card-body text-center">
                    <h5
                      className="card-title mb-2"
                      style={{ Fontsize: '17px' }}
                    >
                      Jonas Acquah
                    </h5>
                    <p className="card-text" style={{ Fontsize: '14px' }}>
                      {' '}
                      Founder &amp; C.E.O
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0">
                <div
                  className="card py-0 bg-transparent shadow-sm"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="1500"
                >
                  {/* <img
                    src={process.env.PUBLIC_URL + '/html/app/dist/images/8.png'}
                    className="m-auto"
                    alt="..."
                    width="200"
                  /> */}
                  <div className="card-body text-center">
                    <h5
                      className="card-title mb-2"
                      style={{ Fontsize: '17px' }}
                    >
                      Brian Paintsil
                    </h5>
                    <p className="card-text" style={{ Fontsize: '14px' }}>
                      Chief Technology Officer
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-none mt-5 justify-content-center">
              <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0">
                <div
                  className="card py-0 bg-transparent shadow-sm"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="1000"
                >
                  {/* <img
                    src={process.env.PUBLIC_URL + '/html/app/dist/images/8.png'}
                    className="m-auto"
                    alt="..."
                    width="200"
                  /> */}
                  <div className="card-body text-center">
                    <h5
                      className="card-title mb-2"
                      style={{ Fontsize: '17px' }}
                    >
                      Akwasi Preprah
                    </h5>
                    <p className="card-text" style={{ Fontsize: '14px' }}>
                      {' '}
                      Advisor, Supply Chain Management
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0">
                <div
                  className="card py-0 bg-transparent shadow-sm"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="1000"
                >
                  {/* <img
                    src={process.env.PUBLIC_URL + '/html/app/dist/images/8.png'}
                    className="m-auto"
                    alt="..."
                    width="200"
                  /> */}
                  {/* <div className="card-body text-center">
                    <h5
                      className="card-title mb-2"
                      style={{ Fontsize: '17px' }}
                    >
                      Nicholas Ryan
                    </h5>
                    <p className="card-text" style={{ Fontsize: '14px' }}>
                      {' '}
                      Advisor, Business Operations
                    </p>
                  </div> */}
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-3 mb-4 mb-md-0">
                <div
                  className="card py-0 bg-transparent shadow-sm"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="1000"
                >
                  {/* <img
                    src={process.env.PUBLIC_URL + '/html/app/dist/images/8.png'}
                    className="m-auto"
                    alt="..."
                    width="200"
                  /> */}
                  <div className="card-body text-center">
                    <h5
                      className="card-title mb-2"
                      style={{ Fontsize: '17px' }}
                    >
                      Robert Bonner
                    </h5>
                    <p className="card-text" style={{ Fontsize: '14px' }}>
                      {' '}
                      Advisor, Legal
                    </p>
                  </div>
                </div>
              </div>
            </div>
          

            {/* <div className="statement text-center pb-5" data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="1000">
              <h4>Jonas Acquah</h4>
              <p className="text-primary">Founder & C.E.O</p>
              <p>Jonas has over 12 years of investment banking finance and business management experience supporting market businesses across multiple asset classes i.e. credit, interest rates, commodities, real estate, equities, fixed income and foreign exchange and served as a CFO Business Manager VP for JPMorgan Chase & Co Amenity and Business Services Organization. Jonas is focused on business strategy with an exceptional technical skill that results in significant cost savings for businesses through automation and balance sheet optimization. Jonas is an FCCA, MCSI with an MBA from Delaware State University and a Bachelor of Applied Accounting from Oxford Brookes University.
</p>
            </div> */}


            <div className="statement text-center pb-5" data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="1000">
              <h4>Robert Bonner</h4>
              <p className="text-primary">Advisor, Legal</p>
              <p>Robert brings more than 15 years of deep experience in the areas of strategic planning, business operations, corporate law, executive coaching and organizational consulting.  His work has centered on building professional and educational ecosystems to support the development of minority entrepreneurs in the business, nonprofit and higher education sectors. Robert received his JD from Harvard University, an MA (Jurisprudence and Social Policy) from Boalt Law School at the University of California-Berkeley, and a PsyM (Clinical Psychology) from Rutgers University.  Robert is also a National Science Foundation Fellow.
</p>
            </div>


            <div className="statement text-center pb-5" data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="1000">
              <h4>Akwasi Preprah</h4>
              <p className="text-primary">Advisor, Supply Chain Management</p>
              <p>Akwasi brings over twenty years of experience driving sourcing process transformation and margin improvements at organizations like Cisco, IBM, and the Alabama Department of Transportation. He’s passionate about building high-performance teams, which for his Operations team means relentlessly pursuing the highest metrics of value creation and delight for our customers. His strong startup, advisory and angel investment experience has propelled lots of startups over the years to accelerate the growth of their respective companies. Akwasi holds an MBA from the University of Minnesota and B.S. in Computer Science from Alabama State University.
</p>
            </div>

            {/* <div className="statement text-center pb-5" data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-duration="1000">
              <h4>Nicholas Ryan</h4>
              <p className="text-primary">Advisor, Business Operations</p>
              <p>Nick is a Vice President at J.P. Morgan Securities LLC where he has worked over the past 7 years to lead a team which ensures the accuracy, integrity, and timeliness of the firm's financial records as a controller supporting the Equities business in the Investment Bank and he’s also a Venture Partner at Cognitive Blockchain Capital where he works with blockchain start-ups to help them shape the future of the industry. Prior to that, Nick gained experience working as a freelancer in web development & SEO, and corporate tax.  Nick believes to be successful, you need to take measured risks, be prudent, anticipate opportunities that may arise and seize them energetically.  Nick is a CPA and he holds a B.S. in Business from the University of California.
</p>
            </div> */}

      


          </div>
        </div>
      </div>

      <div className="svg-container mt-5 pt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width=""
          height=""
          viewBox="0 0 1920.904 187.469"
        >
          <g
            id="Group_10635"
            data-name="Group 10635"
            transform="translate(721.766 -3677.74)"
          >
            <g
              id="Group_10636"
              data-name="Group 10636"
              transform="translate(-721.766 3677.74)"
            >
              <rect
                id="Rectangle_17926"
                data-name="Rectangle 17926"
                width="1920.904"
                height="182.049"
                transform="translate(0 5.42)"
                fill="#005eff"
              />
              <g
                id="_f8f8fcff"
                data-name="#f8f8fcff"
                transform="translate(1920.803 119.608) rotate(180)"
              >
                <path
                  id="Path_9604"
                  data-name="Path 9604"
                  d="M385.9.258C399.259-.154,412.669.064,426.05.014c38.388.451,76.794,1.4,114.907,3.452,19.607.808,39.023,2.24,58.488,3.472,45.581,3.153,90.754,7.3,135.491,12.134,22.311,2.492,44.7,4.879,66.716,7.809,24.6,2.925,48.865,6.309,73.188,9.614,26.686,3.793,53.173,7.845,79.992,11.474,25.5,3.469,51,6.973,76.86,9.957,15.137,2.016,30.577,3.58,45.809,5.465,20.689,2.1,41.2,4.53,62.047,6.341,10.468,1.176,21.163,1.932,31.687,3,6.282.632,12.717.948,19.009,1.576,10.582,1.088,21.428,1.568,32.1,2.472,12.195,1.024,24.561,1.6,36.774,2.56,14.3.8,28.622,1.544,42.914,2.381,9.234.264,18.392.844,27.617,1.172q22.733.714,45.5,1.372c7.193.256,14.425-.02,21.609.292,8.589.352,17.215.164,25.832.24,7.917.019,15.843.051,23.953-.077,3.612-.057,7.418-.153,11.218-.3,21.115-.028,40.916.6,61.927-.152,32.8-1.547,66.517-5.652,98.484-9.031a1216.346,1216.346,0,0,0,126.94-20.1,973.033,973.033,0,0,0,96.211-25.848c13.932-4.5,27.275-9.309,40.732-14.056,6.652-2.66,13.646-5.161,20.517-7.725,6.206-2.232,11.9-4.7,18.24-6.877V119.608H0V68.616c5.476-2.588,9.927-5.525,15.469-8.085q10.2-5.046,21.8-9.581c10.069-3.652,20-7.4,30.833-10.654,30.425-9.569,64.476-16.931,99.7-22.8,12.005-2.072,24.456-3.62,36.774-5.329,30.178-3.9,61.076-6.8,92.4-8.673,10.724-.572,21.372-1.428,32.181-1.656Q357.455.658,385.9.258Z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </Layout>
  );
}
