import React from 'react';
import Layout from '../layout';

export default function ServicePage() {
  return (
    <Layout>
      <div className="">
        <div className="row justify-content-center hero-section no-gutters">
          <div className="col-11 col-md-8 col-lg-8 col-xl-4">
            <div
              className="h-100 d-flex flex-column justify-content-center statement text-center"
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              <h1 className="mb-4 font-weight-bold">
                Control your invoices management and payments
              </h1>
              <p className="mb-4">
                Full solution for the small, medium and large companies to
                automate the whole invoicing process.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 d-none">
            <div
              data-aos="fade-left"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              <img
                src={process.env.PUBLIC_URL + '/html/app/dist/images/10.svg'}
                className="d-none d-md-none d-lg-block"
                width="460"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="box-section">
        <div className="container">
          <div className="row mt-5 pt-5 align-items-center">
            <div className="col-12 col-md-5 col-lg-5 col-xl-5">
              <div
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-duration="1000"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/html/app/dist/images/diagrams/bg/DSO.png'
                  }
                  className="w-100"
                  width=""
                  alt="..."
                />
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-7 col-xl-7">
              <div
                className="card statement"
                data-aos="fade-left"
                data-aos-once="true"
                data-aos-duration="1000"
              >
                <h4 className="card-title font-weight-bold">
                  Early Pay Suppliers and reduce Days Sales Outstanding (DSO)
                </h4>
                <p className="card-text">
                  Cowry’s ability to leverage third party financial institutions
                  or investors, facilitates early payments to suppliers thereby
                  reducing the Days Sales Outstanding. Our unique model and
                  exchange platform allows businesses to have the capital they
                  need when they need it.<br></br>{' '}
                  <span className="font-weight-bold"> The Challenge:</span>
                  In most cases, the cost of vendor borrowing is higher compared
                  to the cost of client borrowing
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5 align-items-center">
            <div className="col-12 col-md-7 col-lg-7 order-1 order-md-0">
              <div
                className="card statement"
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-duration="1000"
              >
                <h4 className="card-title font-weight-bold">
                  Extend Days Payable Outstanding (DPO) for effective cash flow
                  planning
                </h4>
                <p className="card-text">
                  Without impacting buyers balance sheet or cash flow, Cowry is
                  able to extend the DPO with neither an impact on business
                  relationship with Vendor nor impact on Vendor’s cash flow
                  <br></br>{' '}
                  <span className="font-weight-bold"> The Challenge:</span>{' '}
                  Manually tracking customer payment terms, invoice age, status
                  of payments leads to delayed payments causing delinquent
                  bills, unhappy and cash trapped suppliers, increasing cost of
                  supplies and an ineffective client supplier ecosystem.
                </p>
              </div>
            </div>
            <div
              className="col-12 col-md-5 col-lg-5 order-0 order-md-1"
              data-aos="fade-left"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/html/app/dist/images/diagrams/bg/DPO.png'
                }
                className="w-100"
                width=""
                alt="..."
              />
            </div>
          </div>
          <div className="row mt-5 align-items-center">
            <div
              className="col-12 col-md-5 col-lg-5"
              data-aos="fade-right"
              data-aos-once="true"
              data-aos-duration="1000"
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/html/app/dist/images/diagrams/bg/Business Analytics for Planning and Forecasting.png'
                }
                className="w-100"
                width=""
                alt="..."
              />
            </div>
            <div className="col-12 col-md-7 col-lg-7">
              <div
                className="card statement"
                data-aos="fade-left"
                data-aos-once="true"
                data-aos-duration="1000"
              >
                <h4 className="card-title font-weight-bold">
                  Optimize Balance Sheet with multiple lender/investor pricing
                </h4>
                <p className="card-text">
                  Traditional banks are limited to their own balance sheet.
                  Where as Cowry leverages the power of the financial markets to
                  unlock capital within the supply chain so businesses can put
                  it to work. As multiple third party lenders are provided
                  access to supplier early payment opportunities, capital
                  becomes better priced, easily accessible and immediately
                  available. Our strategic work focuses past the immediate
                  problem to developing new and innovative solutions to
                  pragmatic challenges
                  <br></br>{' '}
                  <span className="font-weight-bold"> The Challenge:</span>{' '}
                  Single source pricing from traditional banks results in very
                  expensive capital cost
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-5 align-items-center">
            <div className="col-12 col-md-7 col-lg-7 order-1 order-md-0">
              <div
                className="card statement"
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-duration="1000"
              >
                <h4 className="card-title font-weight-bold">
                  Transform your company to e-invoicing for consistent client
                  supplier communication
                </h4>
                <p className="card-text">
                  Streamlining your invoicing for immediate access to fairly
                  priced capital requires an automated invoicing process,
                  secured communication channel, low risk on demand financing
                  and real time management visibility into client or
                  vendor-data. This results in reduced carbon footprint, error /
                  loss & Fraud prevention, compliance enhancements, shorter
                  payment cycle and reduced costs at less than 90% <br></br>{' '}
                  <span className="font-weight-bold"> The Challenge:</span>{' '}
                  Delivery of paper invoices to clients is becoming obsolete due
                  to how inefficient they are. There is high labour cost of
                  processing paper invoices, longer processing lead times due to
                  the manual nature of data entry, increased operational risk
                  for error and single person dependency leading to
                  incentivization for corruption
                </p>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-5 order-0 order-md-1">
              <div
                data-aos="fade-left"
                data-aos-once="true"
                data-aos-duration="1000"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/html/app/dist/images/diagrams/bg/Secure and Easy Communications.png'
                  }
                  className="w-100"
                  width=""
                  alt="..."
                />
              </div>
            </div>
          </div>
          <div className="row mt-5 align-items-center">
            <div className="col-12 col-md-5 col-lg-5">
              <div
                data-aos="fade-left"
                data-aos-once="true"
                data-aos-duration="1000"
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/html/app/dist/images/diagrams/bg/Realtime Profitability Reporting.png'
                  }
                  className="w-100"
                  width=""
                  alt="..."
                />
              </div>
            </div>
            <div className="col-12 col-md-7 col-lg-7">
              <div
                className="card statement"
                data-aos="fade-right"
                data-aos-once="true"
                data-aos-duration="1000"
              >
                <h4 className="card-title font-weight-bold">
                  Ensure that data drives your success with real time
                  profitability reporting
                </h4>
                <p className="card-text">
                  We increase your capacity to capture supplier data essential
                  for planning and forecasting. And we deepen your ability to
                  understand the impact of data on your bottom line - no matter
                  the size of the business enterprise or venture i.e.
                  Profitability reporting is streamlined and accurate as
                  decision making becomes dependent on real time data point.
                  Cowry also provides in-depth analytics of developing markets,
                  policies, regulations and technical content for project or
                  supply chain finance and decision-making. <br></br>{' '}
                  <span className="font-weight-bold"> The Challenge:</span>{' '}
                  Dependency on manual verification of invoiced orders or at
                  worse, waiting for payable system to catch up with sales.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="svg-container mt-5 pt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width=""
          height=""
          viewBox="0 0 1920.904 187.469"
        >
          <g
            id="Group_10635"
            data-name="Group 10635"
            transform="translate(721.766 -3677.74)"
          >
            <g
              id="Group_10636"
              data-name="Group 10636"
              transform="translate(-721.766 3677.74)"
            >
              <rect
                id="Rectangle_17926"
                data-name="Rectangle 17926"
                width="1920.904"
                height="182.049"
                transform="translate(0 5.42)"
                fill="#005eff"
              />
              <g
                id="_f8f8fcff"
                data-name="#f8f8fcff"
                transform="translate(1920.803 119.608) rotate(180)"
              >
                <path
                  id="Path_9604"
                  data-name="Path 9604"
                  d="M385.9.258C399.259-.154,412.669.064,426.05.014c38.388.451,76.794,1.4,114.907,3.452,19.607.808,39.023,2.24,58.488,3.472,45.581,3.153,90.754,7.3,135.491,12.134,22.311,2.492,44.7,4.879,66.716,7.809,24.6,2.925,48.865,6.309,73.188,9.614,26.686,3.793,53.173,7.845,79.992,11.474,25.5,3.469,51,6.973,76.86,9.957,15.137,2.016,30.577,3.58,45.809,5.465,20.689,2.1,41.2,4.53,62.047,6.341,10.468,1.176,21.163,1.932,31.687,3,6.282.632,12.717.948,19.009,1.576,10.582,1.088,21.428,1.568,32.1,2.472,12.195,1.024,24.561,1.6,36.774,2.56,14.3.8,28.622,1.544,42.914,2.381,9.234.264,18.392.844,27.617,1.172q22.733.714,45.5,1.372c7.193.256,14.425-.02,21.609.292,8.589.352,17.215.164,25.832.24,7.917.019,15.843.051,23.953-.077,3.612-.057,7.418-.153,11.218-.3,21.115-.028,40.916.6,61.927-.152,32.8-1.547,66.517-5.652,98.484-9.031a1216.346,1216.346,0,0,0,126.94-20.1,973.033,973.033,0,0,0,96.211-25.848c13.932-4.5,27.275-9.309,40.732-14.056,6.652-2.66,13.646-5.161,20.517-7.725,6.206-2.232,11.9-4.7,18.24-6.877V119.608H0V68.616c5.476-2.588,9.927-5.525,15.469-8.085q10.2-5.046,21.8-9.581c10.069-3.652,20-7.4,30.833-10.654,30.425-9.569,64.476-16.931,99.7-22.8,12.005-2.072,24.456-3.62,36.774-5.329,30.178-3.9,61.076-6.8,92.4-8.673,10.724-.572,21.372-1.428,32.181-1.656Q357.455.658,385.9.258Z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </Layout>
  );
}
