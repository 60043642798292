import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Layout from '../layout';
import { postData } from '../../api';

export default function ContactPage() {
  const [inputs, setInputs] = useState({});

  const handleInputChange = event => {
    event.persist();
    setInputs(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value
    }));
  };

  const submitForm = (e) => {
    e.preventDefault();
    postData(
      'https://api.cowryllc.com/api/ContactAndNewsletter/ContactUs',
      inputs,
      'POST'
    ).then(res =>
      setInputs(prevState => ({
        ...prevState,
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        role: ''
      }))
    );
  };

  const { name, email, phoneNumber, role, message } = inputs;

  return (
    <Layout>
      <div className="world-container">
        <div className="world-banner">
          <div className="row justify-content-center no-gutters"></div>
        </div>
      </div>

      <div className="container">
        <div className="contact-form">
          <p className="text-primary d-none">Message sent successfully, we'll get back to you shortly.</p>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <h3>Get in touch</h3>
              <div className="row mt-4">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  <div className="form-group custom">
                    <label htmlFor="exampleInputEmail1" className="">
                      Your Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name Last Name"
                      name="name"
                      value={name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom">
                    <label htmlFor="exampleInputEmail1" className="">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="email@mail.com"
                      name="email"
                      value={email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  <div className="form-group custom">
                    <label htmlFor="exampleInputEmail1" className="">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="+233 123 456 7890"
                      name="phone"
                      value={phoneNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="form-group custom">
                    <label htmlFor="exampleInputEmail1" className="">
                      Role
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Role"
                      name="role"
                      value={role}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="form-group custom">
                  <label htmlFor="exampleInputEmail1" className="">
                    Message
                  </label>
                  <textarea
                    type="text"
                    className="form-control pt-4 h-100"
                    rows="4"
                    placeholder="What would you like to ask us?"
                    name="message"
                    value={message}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-5 flex-column flex-lg-row">
                <div className="form-group form-check p-0">
                  <ReCAPTCHA
                    onChange={value => console.log(value)}
                    sitekey="6LdLir4UAAAAAFj3t5i6pjIzwh1AE4UONaD5vZKk"
                  />
                </div>

                <button
                  className="btn btn-secondary"
                  onClick={(e) => submitForm(e)}
                >
                  Submit
                </button>

                <button
                  className="btn btn-secondary d-none"
                  onClick={(e) => submitForm(e)}
                >
                  Message Sent  <i class="fas ml-2 fa-check"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="svg-container mt-5 pt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width=""
          height=""
          viewBox="0 0 1920.904 187.469"
        >
          <g
            id="Group_10635"
            data-name="Group 10635"
            transform="translate(721.766 -3677.74)"
          >
            <g
              id="Group_10636"
              data-name="Group 10636"
              transform="translate(-721.766 3677.74)"
            >
              <rect
                id="Rectangle_17926"
                data-name="Rectangle 17926"
                width="1920.904"
                height="182.049"
                transform="translate(0 5.42)"
                fill="#005eff"
              />
              <g
                id="_f8f8fcff"
                data-name="#f8f8fcff"
                transform="translate(1920.803 119.608) rotate(180)"
              >
                <path
                  id="Path_9604"
                  data-name="Path 9604"
                  d="M385.9.258C399.259-.154,412.669.064,426.05.014c38.388.451,76.794,1.4,114.907,3.452,19.607.808,39.023,2.24,58.488,3.472,45.581,3.153,90.754,7.3,135.491,12.134,22.311,2.492,44.7,4.879,66.716,7.809,24.6,2.925,48.865,6.309,73.188,9.614,26.686,3.793,53.173,7.845,79.992,11.474,25.5,3.469,51,6.973,76.86,9.957,15.137,2.016,30.577,3.58,45.809,5.465,20.689,2.1,41.2,4.53,62.047,6.341,10.468,1.176,21.163,1.932,31.687,3,6.282.632,12.717.948,19.009,1.576,10.582,1.088,21.428,1.568,32.1,2.472,12.195,1.024,24.561,1.6,36.774,2.56,14.3.8,28.622,1.544,42.914,2.381,9.234.264,18.392.844,27.617,1.172q22.733.714,45.5,1.372c7.193.256,14.425-.02,21.609.292,8.589.352,17.215.164,25.832.24,7.917.019,15.843.051,23.953-.077,3.612-.057,7.418-.153,11.218-.3,21.115-.028,40.916.6,61.927-.152,32.8-1.547,66.517-5.652,98.484-9.031a1216.346,1216.346,0,0,0,126.94-20.1,973.033,973.033,0,0,0,96.211-25.848c13.932-4.5,27.275-9.309,40.732-14.056,6.652-2.66,13.646-5.161,20.517-7.725,6.206-2.232,11.9-4.7,18.24-6.877V119.608H0V68.616c5.476-2.588,9.927-5.525,15.469-8.085q10.2-5.046,21.8-9.581c10.069-3.652,20-7.4,30.833-10.654,30.425-9.569,64.476-16.931,99.7-22.8,12.005-2.072,24.456-3.62,36.774-5.329,30.178-3.9,61.076-6.8,92.4-8.673,10.724-.572,21.372-1.428,32.181-1.656Q357.455.658,385.9.258Z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </Layout>
  );
}
