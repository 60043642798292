import React, { useState } from 'react';
import Layout from '../layout';

export default function TermsPage() {
  return (
    <Layout>
      <div className="world-container">
        <div className="world-banner container justify-content-start">
          <div className="row justify-content-start flex-grow-1 no-gutters">
            <div className="col-12 col-md-6">
              <h3 className="text-left">Terms Of Use</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="mt-5">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active show px-5"
                id="contact-tab"
                data-toggle="tab"
                href="#contact"
                role="tab"
                aria-controls="con tact"
                aria-selected="true"
              >
                Privacy Policy
              </a>
            </li>
            {/* <li className="nav-item">
              <a
                className="nav-link px-5"
                id="vendor-tab"
                data-toggle="tab"
                href="#vendor"
                role="tab"
                aria-controls="vendor"
                aria-selected="false"
              >
                Terms And Conditions
              </a>
            </li> */}
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div className="py-4">
                <p>
                  COWRY respects your privacy and are committed to protecting
                  personally identifiable information you may provide us through
                  this website in compliance with data protection laws.
                </p>

                <p>
                  This Privacy Policy explains how we use the personal
                  information that COWRY collects or generates both in relation
                  to this website and our products and services.
                </p>

                <h5>Collection Of Information</h5>

                <ul>
                  <li>
                    While using our Service, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you ("Personal Data"). Personally
                    identifiable information may include, but is not limited to:
                    Email address, First name and last name, Phone number,
                    Address, State, Province, ZIP/Postal code, City, Cookies and
                    Usage Data.
                  </li>
                  <li>
                    The information which is associated with your individual
                    profile enables you to participate in activities, complete a
                    registration, request a demo or to order products and
                    services using these websites. If you provide us with
                    personal information, you shall have the right, on
                    reasonable prior notice, to inspect, change, amend, or
                    delete such information. Instructions on how to update
                    personal information will be given at the point of data
                    collection.
                  </li>
                  <li>
                    For each visitor to our website, our web server may
                    automatically recognize and store information, such as the
                    visitor’s IP address/domain name combination, usage of
                    organization’s computer users (e.g. what websites were
                    visited by each user, documents downloaded and any security
                    incidents etc). When you visit our websites or use one or
                    more of our website services, we may use “cookies”, which
                    stores certain information on your computer and to customize
                    your experience to better match your interests and
                    preferences.
                  </li>
                </ul>

                <h5>Use of Collected Information</h5>
                <ul>
                  <li>
                    Usage data including IP address / domain name information
                    that we collect is not used to personally identify you and
                    instead is aggregated to measure the number of visits,
                    average time spent on the site, pages viewed etc. We use
                    this information to measure website usage and to improve the
                    content of our website.
                  </li>
                  <li>
                    We use personally identifying information to respond to your
                    needs and communicate with you about Cowry’s products and
                    services, receive direct feedback on those services /
                    products, meet regulatory requests and for ongoing
                    management / administration of our business.
                  </li>
                </ul>

                <h5>Use of Collected Information</h5>
                <ul>
                  <li>
                    We strongly advise you to review the Privacy Policy and
                    terms and conditions of every site you visit, including
                    external links from our website. We have no control over,
                    and assume no responsibility for the content, privacy
                    policies or practices of any third party sites, products or
                    services.
                  </li>
                  <li>
                    We may share personal data internally within the Cowry LLC
                    and externally outside of Cowry LLC. The purpose of this
                    share will always be for one of the following reasons:
                    <ul>
                      <li>For business continuity</li>
                      <li>For business transformation</li>
                      <li>For regulatory requirements</li>
                      <li>For providing additional services</li>
                    </ul>
                  </li>
                </ul>
                <h5>Safeguarding Collected Information</h5>
                <ul>
                  <li>
                    We have controls to ensure all clients personal information
                    are secured including controls that mandate that all Cowry’s
                    employees follow applicable laws and regulations including
                    data protection laws or stand the risk of disciplinary
                    measure.
                  </li>
                </ul>

                <h5>How long we keep personal data</h5>
                <ul>
                  <li>
                    How long we keep personal data vary by the purpose for which
                    we obtained it in the first place and our legal obligations
                    regarding that data.
                  </li>
                </ul>
                <h5>Changes To This Privacy Policy</h5>
                <ul>
                  <li>
                    We may update our Privacy Policy from time to time and
                    notify you of any changes by posting the new Privacy Policy
                    on this page with an update on the "effective date" at the
                    top of this Privacy Policy.
                  </li>
                  <li>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page for new users or
                    30 days after they are posted for existing users
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="vendor"
              role="tabpanel"
              aria-labelledby="vendor-tab"
            >
              <p>dddd</p>
            </div>
          </div>
        </div>
      </div>

      <div className="svg-container mt-5 pt-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width=""
          height=""
          viewBox="0 0 1920.904 187.469"
        >
          <g
            id="Group_10635"
            data-name="Group 10635"
            transform="translate(721.766 -3677.74)"
          >
            <g
              id="Group_10636"
              data-name="Group 10636"
              transform="translate(-721.766 3677.74)"
            >
              <rect
                id="Rectangle_17926"
                data-name="Rectangle 17926"
                width="1920.904"
                height="182.049"
                transform="translate(0 5.42)"
                fill="#005eff"
              />
              <g
                id="_f8f8fcff"
                data-name="#f8f8fcff"
                transform="translate(1920.803 119.608) rotate(180)"
              >
                <path
                  id="Path_9604"
                  data-name="Path 9604"
                  d="M385.9.258C399.259-.154,412.669.064,426.05.014c38.388.451,76.794,1.4,114.907,3.452,19.607.808,39.023,2.24,58.488,3.472,45.581,3.153,90.754,7.3,135.491,12.134,22.311,2.492,44.7,4.879,66.716,7.809,24.6,2.925,48.865,6.309,73.188,9.614,26.686,3.793,53.173,7.845,79.992,11.474,25.5,3.469,51,6.973,76.86,9.957,15.137,2.016,30.577,3.58,45.809,5.465,20.689,2.1,41.2,4.53,62.047,6.341,10.468,1.176,21.163,1.932,31.687,3,6.282.632,12.717.948,19.009,1.576,10.582,1.088,21.428,1.568,32.1,2.472,12.195,1.024,24.561,1.6,36.774,2.56,14.3.8,28.622,1.544,42.914,2.381,9.234.264,18.392.844,27.617,1.172q22.733.714,45.5,1.372c7.193.256,14.425-.02,21.609.292,8.589.352,17.215.164,25.832.24,7.917.019,15.843.051,23.953-.077,3.612-.057,7.418-.153,11.218-.3,21.115-.028,40.916.6,61.927-.152,32.8-1.547,66.517-5.652,98.484-9.031a1216.346,1216.346,0,0,0,126.94-20.1,973.033,973.033,0,0,0,96.211-25.848c13.932-4.5,27.275-9.309,40.732-14.056,6.652-2.66,13.646-5.161,20.517-7.725,6.206-2.232,11.9-4.7,18.24-6.877V119.608H0V68.616c5.476-2.588,9.927-5.525,15.469-8.085q10.2-5.046,21.8-9.581c10.069-3.652,20-7.4,30.833-10.654,30.425-9.569,64.476-16.931,99.7-22.8,12.005-2.072,24.456-3.62,36.774-5.329,30.178-3.9,61.076-6.8,92.4-8.673,10.724-.572,21.372-1.428,32.181-1.656Q357.455.658,385.9.258Z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    </Layout>
  );
}
