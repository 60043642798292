import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <nav
    className="navbar navbar-expand-lg navbar-dark py-0 web navbar-main"
    id="myHeader"
  >
    <div className="container">
      <Link className="navbar-brand" to="/">
        <svg
          width="300"
          height="68"
          viewBox="0 0 364 68"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.26905 47.4012C3.18172 31.8103 13.7893 12.6862 19.4831 3.83594C15.012 1.3801 11.9087 0.046936 11.9087 0.046936C11.9087 0.046936 -5.19909 29.9179 1.59522 50.2502C5.33883 61.4516 16.0501 68.3553 27.2969 67.7646C17.034 66.0728 8.40862 58.2059 6.26905 47.4012Z"
            fill="#001C4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.32586 37.591C11.4288 26.6068 20.4736 16.5461 28.6465 9.43298C25.3492 7.19229 22.1695 5.31171 19.483 3.83563C13.7892 12.6864 3.18162 31.8105 6.26896 47.4015C8.40905 58.2061 17.0339 66.073 27.2968 67.7643C27.9804 67.7287 28.6656 67.6662 29.3518 67.5744C15.6295 64.7363 6.68171 51.4005 9.32586 37.591Z"
            fill="#005FFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.6463 34.0818C18.4499 25.5699 25.5991 18.2205 32.9703 12.5468C31.5272 11.4437 30.0759 10.4041 28.6462 9.43311C20.4739 16.5463 11.4286 26.6069 9.32613 37.5911C6.68198 51.4001 15.6293 64.7364 29.352 67.5745C30.5655 67.4115 31.78 67.1623 32.9883 66.8176C32.6617 66.7243 32.3352 66.6299 32.0091 66.5225C18.5324 62.0824 11.2072 47.5584 15.6463 34.0818Z"
            fill="#5D99FF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.9242 0C53.9242 0 50.8168 1.35071 46.3452 3.83286C52.1035 12.6734 62.7745 31.6798 59.793 47.2542C57.7112 58.128 49.058 66.0775 38.7357 67.779C50.0171 68.3626 60.7454 61.4062 64.4503 50.1594C71.1569 29.7987 53.9242 0 53.9242 0Z"
            fill="#001C4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M59.7929 47.2541C62.7745 31.6797 52.1035 12.6738 46.3452 3.83276C43.6747 5.31504 40.5177 7.20077 37.2452 9.44353C45.4438 16.5025 54.5083 26.4811 56.6747 37.421C59.4204 51.2882 50.4468 64.7535 36.6168 67.5798C37.3241 67.6757 38.0309 67.7423 38.7357 67.7789C49.0579 66.0774 57.7112 58.1279 59.7929 47.2541Z"
            fill="#005FFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.3308 33.9659C54.8282 47.4239 47.5628 61.9789 34.1053 66.4758C33.7338 66.6001 33.3613 66.7115 32.9883 66.8178C34.1945 67.1635 35.4059 67.4148 36.6163 67.5793C50.4469 64.7536 59.4205 51.2883 56.6747 37.4211C54.5083 26.4812 45.4439 16.5021 37.2452 9.4436C35.8316 10.4125 34.3968 11.4485 32.9702 12.5464C40.3455 18.183 47.4973 25.487 50.3308 33.9659Z"
            fill="#5D99FF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.7914 51.4123C93.6335 46.0084 92 38.9717 92 32.8767C92 28.6037 93.3194 21.1893 99.3509 15.6598C103.875 11.5138 109.845 9 119.207 9C123.102 9 125.427 9.25131 128.317 9.62828C130.705 9.94242 132.715 10.5079 134.601 10.6964C135.292 10.7592 135.543 11.0733 135.543 11.4503C135.543 11.9529 135.355 12.7075 135.228 14.9065C135.103 16.9798 135.166 20.436 135.04 21.6926C134.978 22.6344 134.852 23.137 134.287 23.137C133.783 23.137 133.658 22.6344 133.658 21.7548C133.596 19.8071 132.778 17.6081 131.207 15.9746C129.134 13.7756 124.233 12.0786 118.452 12.0786C112.986 12.0786 109.405 13.4615 106.64 15.9746C102.053 20.1841 100.859 26.1534 100.859 32.2478C100.859 47.2028 112.232 54.6166 120.652 54.6166C126.244 54.6166 129.637 53.9889 132.15 51.0982C133.219 49.9038 134.035 48.0824 134.287 46.9509C134.475 45.9463 134.601 45.6943 135.103 45.6943C135.543 45.6943 135.669 46.1347 135.669 46.6374C135.669 47.3913 134.914 52.8574 134.287 54.9935C133.972 56.0622 133.721 56.3757 132.653 56.8162C130.139 57.8214 125.364 58.2612 121.343 58.2612C112.735 58.2612 105.509 56.3757 99.7914 51.4123Z"
            fill="#001C4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M184.8 34.8244C184.8 20.7502 176.694 12.0164 166.39 12.0164C159.164 12.0164 150.933 16.0374 150.933 31.6829C150.933 44.7519 158.158 55.1192 170.222 55.1192C174.621 55.1192 184.8 52.983 184.8 34.8244ZM142.262 33.6306C142.262 22.8863 149.362 9 168.212 9C183.857 9 193.596 18.1107 193.596 32.4997C193.596 46.888 183.543 58.2612 167.584 58.2612C149.551 58.2612 142.262 44.7519 142.262 33.6306Z"
            fill="#001C4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M200.477 16.0376C199.723 13.7123 199.032 12.2044 198.152 11.5762C197.461 11.1364 196.204 11.0107 195.639 11.0107C195.011 11.0107 194.634 10.9485 194.634 10.4452C194.634 10.0054 195.136 9.87979 196.016 9.87979C199.284 9.87979 202.739 10.0676 203.305 10.0676C203.87 10.0676 206.635 9.87979 210.279 9.87979C211.159 9.87979 211.599 10.0676 211.599 10.4452C211.599 10.9485 211.095 11.0107 210.468 11.0107C209.965 11.0107 209.336 11.0107 208.897 11.262C208.332 11.5133 208.206 11.9531 208.206 12.6442C208.206 13.461 208.834 15.5972 209.588 18.4251C210.656 22.5718 216.499 41.6106 217.631 45.129H217.819L230.009 12.3301C230.637 10.5709 231.077 10.0676 231.58 10.0676C232.271 10.0676 232.585 11.0107 233.401 13.0212L246.471 44.6886H246.659C247.79 40.4791 252.817 23.8918 255.016 16.5396C255.456 15.0945 255.645 13.7758 255.645 12.8962C255.645 11.8903 255.079 11.0107 252.629 11.0107C252 11.0107 251.561 10.8222 251.561 10.4452C251.561 10.0054 252.063 9.87979 253.006 9.87979C256.336 9.87979 258.974 10.0676 259.541 10.0676C259.98 10.0676 262.682 9.87979 264.755 9.87979C265.446 9.87979 265.886 10.0054 265.886 10.3824C265.886 10.8222 265.572 11.0107 265.007 11.0107C264.378 11.0107 263.499 11.0735 262.808 11.3248C261.3 11.8903 260.609 14.4034 259.289 17.9853C256.399 25.9023 249.802 45.883 246.722 55.3079C246.031 57.5075 245.716 58.2608 244.9 58.2608C244.272 58.2608 243.895 57.5075 242.953 55.1822L229.883 23.6398H229.757C228.564 26.8441 220.207 49.1501 217.505 54.9309C216.311 57.5075 215.997 58.2608 215.243 58.2608C214.615 58.2608 214.363 57.5697 213.798 55.9361L200.477 16.0376Z"
            fill="#001C4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M284.857 33.065C284.857 33.7561 284.983 34.0074 285.423 34.1959C286.742 34.6357 288.628 34.8242 290.198 34.8242C292.711 34.8242 293.528 34.5735 294.659 33.7561C296.544 32.3739 298.366 29.4838 298.366 24.3312C298.366 15.409 292.46 12.8324 288.753 12.8324C287.182 12.8324 286.051 12.8959 285.423 13.0843C284.983 13.2094 284.857 13.4613 284.857 13.9639V33.065ZM277.443 28.1016C277.443 18.5504 277.443 16.7912 277.317 14.7801C277.192 12.6439 276.689 11.6386 274.616 11.1995C274.113 11.0732 273.045 11.011 272.165 11.011C271.474 11.011 271.097 10.8847 271.097 10.4456C271.097 10.0051 271.537 9.87946 272.479 9.87946C275.809 9.87946 279.768 10.0679 281.088 10.0679C283.224 10.0679 287.999 9.87946 289.946 9.87946C293.905 9.87946 298.115 10.2571 301.508 12.5817C303.267 13.7755 305.781 16.9797 305.781 21.1898C305.781 25.8391 303.833 30.1121 297.486 35.2647C303.079 42.3014 307.415 47.8938 311.121 51.7891C314.64 55.4338 317.216 55.873 318.159 56.0621C318.85 56.1871 319.415 56.2506 319.918 56.2506C320.421 56.2506 320.672 56.4391 320.672 56.7526C320.672 57.2552 320.232 57.3815 319.478 57.3815H313.509C309.99 57.3815 308.42 57.0667 306.786 56.1871C304.084 54.7427 301.697 51.7891 298.177 46.9507C295.664 43.4958 292.774 39.2222 291.957 38.2804C291.643 37.9034 291.266 37.84 290.827 37.84L285.36 37.715C285.046 37.715 284.857 37.84 284.857 38.2169V39.0965C284.857 44.9402 284.857 49.9043 285.171 52.5431C285.36 54.3657 285.737 55.748 287.622 55.9986C288.564 56.1249 289.946 56.2506 290.701 56.2506C291.203 56.2506 291.455 56.4391 291.455 56.7526C291.455 57.193 291.014 57.3815 290.198 57.3815C286.554 57.3815 281.904 57.193 280.962 57.193C279.768 57.193 275.809 57.3815 273.296 57.3815C272.479 57.3815 272.04 57.193 272.04 56.7526C272.04 56.4391 272.29 56.2506 273.045 56.2506C273.987 56.2506 274.741 56.1249 275.307 55.9986C276.563 55.748 276.877 54.3657 277.129 52.5431C277.443 49.9043 277.443 44.9402 277.443 39.16V28.1016Z"
            fill="#001C4C"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M335.788 41.1077C335.788 37.715 335.16 36.3327 334.342 34.5101C333.903 33.5055 325.483 19.6813 323.284 16.4142C321.713 14.089 320.143 12.5182 318.948 11.8278C318.132 11.388 317.063 11.011 316.309 11.011C315.807 11.011 315.367 10.8847 315.367 10.4456C315.367 10.0679 315.807 9.87946 316.561 9.87946C317.692 9.87946 322.404 10.0679 324.478 10.0679C325.797 10.0679 328.374 9.87946 331.201 9.87946C331.829 9.87946 332.144 10.0679 332.144 10.4456C332.144 10.8225 331.704 10.8847 330.761 11.1367C330.195 11.2617 329.882 11.7643 329.882 12.2676C329.882 12.7702 330.195 13.5241 330.636 14.4037C331.515 16.2886 340.312 31.4315 341.317 33.1907C341.945 31.6828 349.862 17.8593 350.742 15.9116C351.371 14.5294 351.622 13.4613 351.622 12.6445C351.622 12.0163 351.371 11.3251 350.302 11.0732C349.737 10.9482 349.171 10.8225 349.171 10.4456C349.171 10.0051 349.485 9.87946 350.239 9.87946C352.313 9.87946 354.323 10.0679 356.146 10.0679C357.528 10.0679 361.675 9.87946 362.868 9.87946C363.56 9.87946 364 10.0051 364 10.3821C364 10.8225 363.497 11.011 362.806 11.011C362.177 11.011 360.984 11.2617 360.041 11.7643C358.722 12.3932 358.031 13.1472 356.648 14.8435C354.575 17.3567 345.779 32.1226 344.647 34.8242C343.705 37.0867 343.705 39.0344 343.705 41.1077V46.2596C343.705 47.2649 343.705 49.9043 343.894 52.5431C344.019 54.3657 344.585 55.748 346.47 55.9993C347.349 56.1249 348.731 56.2506 349.611 56.2506C350.302 56.2506 350.616 56.4391 350.616 56.7526C350.616 57.193 350.114 57.3815 349.297 57.3815C345.338 57.3815 341.38 57.193 339.684 57.193C338.113 57.193 334.154 57.3815 331.829 57.3815C330.95 57.3815 330.447 57.2552 330.447 56.7526C330.447 56.4391 330.761 56.2506 331.452 56.2506C332.332 56.2506 333.086 56.1249 333.651 55.9993C334.908 55.748 335.474 54.3657 335.599 52.5431C335.788 49.9043 335.788 47.2649 335.788 46.2596V41.1077Z"
            fill="#001C4C"
          />
        </svg>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {/* <span className="navbar-toggler-icon"></span> */}
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          className="topbar__button-icon"
          width="22"
          height="32"
          viewBox="0 0 32 32"
        >
          <title>drawer</title>
          <path d="M0.503 1.993c0.228-0.077 0.491-0.122 0.764-0.122 0.089 0 0.177 0.005 0.263 0.014l-0.011-0.001h29.79c0.021-0.001 0.045-0.002 0.069-0.002 0.335 0 0.636 0.146 0.843 0.377l0.001 0.001c0.104 0.156 0.167 0.347 0.167 0.553 0 0.055-0.004 0.109-0.013 0.162l0.001-0.006q0 1.389 0 2.78c0.008 0.047 0.012 0.101 0.012 0.156 0 0.194-0.055 0.374-0.15 0.527l0.002-0.004c-0.208 0.233-0.509 0.38-0.845 0.38-0.030 0-0.060-0.001-0.089-0.003l0.004 0h-29.792c-0.076 0.008-0.164 0.012-0.253 0.012-0.267 0-0.526-0.040-0.768-0.115l0.018 0.005c-0.257-0.096-0.45-0.31-0.515-0.574l-0.001-0.006v-3.533c0.059-0.275 0.248-0.496 0.497-0.6l0.006-0.002z"></path>
          <path d="M0.218 14.035c0.124-0.136 0.277-0.094 0.416-0.1h12.472c0.243-0.026 0.45 0.365 0.442 0.781 0 1.048 0 2.097 0 3.145 0.004 0.043 0.006 0.094 0.006 0.144 0 0.205-0.037 0.401-0.104 0.582l0.004-0.012c-0.049 0.167-0.2 0.287-0.38 0.287-0.009 0-0.018-0-0.028-0.001l0.001 0q-6.313 0-12.629 0c-0.215 0-0.399-0.307-0.424-0.674v-3.541c0.005-0.234 0.088-0.447 0.224-0.616l-0.002 0.002z"></path>
          <path d="M0 26.692c0.114-0.415 0.487-0.715 0.931-0.715 0.027 0 0.055 0.001 0.081 0.003l-0.004-0q15.151 0 30.306 0c0.019-0.001 0.042-0.002 0.064-0.002 0.316 0 0.603 0.129 0.809 0.337l0 0c0.118 0.132 0.19 0.308 0.19 0.5 0 0.019-0.001 0.039-0.002 0.058l0-0.003c0 1.096 0 2.193 0 3.288 0.013 0.414-0.499 0.781-1.065 0.753h-30.236c-0.039 0.005-0.083 0.008-0.128 0.008-0.438 0-0.81-0.282-0.944-0.675l-0.002-0.007z"></path>
        </svg>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0 mb-md-0 navbar-main-links">
          <li className="nav-item ">
            <Link className="nav-link" to="/">
              Home <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item ">
            <Link className="nav-link" to="/about">
              About <span className="sr-only">(current)</span>
            </Link>
          </li>
          <li className="nav-item ">
            <Link className="nav-link" to="/services">
              Services
            </Link>
          </li>
          <li className="nav-item ">
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
          </li>
          <li className="nav-item ">
            {/* <a className="btn btn-secondary" href="sign-up/business-info.html">Sign up</a> */}

            <a href="https://app.cowryllc.com" className="btn btn-secondary">
              Log In
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

export default Header;
