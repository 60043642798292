import React from 'react';
import Header from './header';
import Footer from './footer';

export default function Layout({ children }) {
    return (
        <>
            <div className="wrapper">
                <div className="content">
                    <Header />
                    {children}
                </div>
            </div>

            <Footer />
        </>
    );
}
